import React, { useEffect, useState, useRef } from "react";
import {toast} from "react-toastify";

const api = process.env.NODE_ENV === 'production' ? 'https://ss-api.samplestack.me/' : 'http://localhost/';

function EmptyRack({ selectedSite, readonly }) {
    const [racks, setRacks] = useState([]);
    const isMountedRef = useRef(false);
    const [rackId, setRackId] = useState(null);

    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
            fetch(`${api}api/rack/select/${selectedSite.value}`)
                .then((response) => response.json())
                .then((data) => {
                    setRacks(data.racks);
                })
                .catch((error) => {
                    console.log("Error fetching data:", error);
                });
        }
    }, [selectedSite.value]);

    const handleEmptyRack = () => {

        if(!rackId) return;

        //Confirm empty rack
        if(!window.confirm(`Are you sure you want to empty this rack?`)) return;

        //Send sampleId and siteId to API and handle response
        fetch(`${api}api/rack/empty/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                rack: rackId
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    console.error(data.message);
                    toast.error(data.message);
                } else {
                    toast.success("Successfully emptied rack");
                }
            })
            .catch((error) => {
                console.error("Error emptying rack:", error);
                toast.error(error);
            });

    };


    const renderContent = () => {

        // Group racks by location
        const groupedRacks = {};
        racks.forEach((rack) => {
            if (!groupedRacks[rack.location]) {
                groupedRacks[rack.location] = [];
            }
            groupedRacks[rack.location].push(rack);
        });

        return (
            <div>

                <h2>Select a Rack</h2>
                <div style={{ display: "flex", width: "100%" }}>
                    <select
                        style={{
                            flex: "1",
                            marginRight: "5px",
                        }}
                        onChange={(e) => {
                            const selectElement = e.target;
                            const selectedOption = selectElement.options[selectElement.selectedIndex];
                            const dataId = selectedOption.getAttribute('data-id');
                            setRackId(dataId);
                        }}
                    >
                        <option value="">-- Select a Rack --</option>
                        {Object.entries(groupedRacks).map(([location, racks]) => (
                            <optgroup key={location} label={location}>
                                {racks.map((rack) => (
                                    <option key={rack.id} value={rack.key} data-id={rack.id}>
                                        {rack.name}
                                    </option>
                                ))}
                            </optgroup>
                        ))}

                    </select>
                    <button
                        style={{ flex: "none" }}
                        onClick={handleEmptyRack}
                    >
                        Empty Rack
                    </button>
                </div>

            </div>
        );

    };

    return renderContent();
}

export default EmptyRack;