import React, { useEffect, useState, useRef } from "react";
import Tooltip from "./Tooltip";
import {toast} from "react-toastify";

const api = process.env.NODE_ENV === 'production' ? 'https://ss-api.samplestack.me/' : 'http://localhost/';

function StoreSample({ selectedSite, readonly }) {
    const [racks, setRacks] = useState([]);
    const [selectedRack, setSelectedRack] = useState({ value: "", name: "", id: 0 });
    const isMountedRef = useRef(false);
    const [rackData, setRackData] = useState(null);
    const [sampleInput, setSampleInput] = useState("");
    const inputRef = useRef(null);
    const [hoveredSample, setHoveredSample] = useState(null);

    const [editedSamples, setEditedSamples] = useState({});

    const [inputValues, setInputValues] = useState({});

    const handleInputChange = (sampleId, newValue) => {
        setInputValues((prev) => ({ ...prev, [sampleId]: newValue }));
    };

    const isValueChanged = (sampleId, value) => {
        return inputValues[sampleId] !== undefined && inputValues[sampleId] !== value;
    };

    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
            fetch(`${api}api/rack/select/${selectedSite.value}`)
                .then((response) => response.json())
                .then((data) => {
                    setRacks(data.racks);
                })
                .catch((error) => {
                    console.log("Error fetching data:", error);
                });
        }
    }, [selectedSite.value]);

    useEffect(() => {
        // Add this to focus the input element when it is rendered
        if(inputRef.current){
            inputRef.current.focus();
        }
    }, [rackData]);


    const handleRackChange = (e) => {
        const selectedRackOption = e.target.options[e.target.selectedIndex];
        const selectedRackValue = selectedRackOption.value;
        const selectedRackName = selectedRackOption.text;
        const selectedRackId = selectedRackOption.dataset.id;

        setSelectedRack({ value: selectedRackValue, name: selectedRackName, id: selectedRackId });

        // Make the second API call
        fetch(`${api}api/rack/show/${selectedRackId}`)
            .then((response) => response.json())
            .then((data) => {
                setRackData(data);
            })
            .catch((error) => {
                console.log("Error fetching rack data:", error);
            });
    };

    const handleSampleInputChange = (e) => {
        setSampleInput(e.target.value);
    };

    const handleSampleInputKeyPress = (e) => {
        if(e.key === 'Enter'){
            handleAddSampleClick();
        }
    };

    // Handler for the "Add Sample" button click
    const handleAddSampleClick = () => {
        // Send a POST request to the server

        if(!sampleInput) { return; }

        fetch(`${api}api/sample/add/${selectedRack.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                sample: sampleInput
            })
        })
            .then((response) => response.json())
            .then((data) => {
                // Update the rackData based on the response
                if(data.error) {
                    // The rack is full or some other error occurred
                    console.error(data.message);
                    toast.error(data.message);
                } else {

                    setRackData((prevRackData) => ({
                        ...prevRackData,
                        samples: [...prevRackData.samples, data]
                    }));

                    // Clear the sample input
                    setSampleInput("");
                }
            })
            .catch((error) => {
                console.error("Error adding sample:", error);
            });
    };

    // Handler for "change rack" button
    const handleChangeRackClick = () => {
        setSelectedRack({ value: "", name: "", id: 0 });
        setRackData(null);
    };

    const handleCheckboxChange = (sampleId, moved, sampleValue) => {

        const updatedSamples = rackData.samples.map(sample => {
            if (sample.id === sampleId) {
                return { ...sample, moved: moved };
            }
            return sample;
        });

        // Set the updated data
        setRackData(prevData => ({ ...prevData, samples: updatedSamples }));

        // Send a POST request to the server
        fetch(`${api}api/sample/move/${sampleId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                moved: moved,
                sampleId: sampleId,
                sampleValue: sampleValue
            })
        })
            .then((response) => response.json())
            .then((data) => {
                // Update the rackData based on the response
                if(data.error) {
                    // Some error occurred
                    console.error(data.message);
                    toast.error(data.message);
                } else {
                    //Do nothing for now
                }
            })
            .catch((error) => {
                console.error("Error moving sample:", error);
            });

    }

    const handleSave = (sampleId, newValue) => {
        fetch(`${api}api/sample/update/${sampleId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                value: newValue,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.error(data.message);
                    toast.error(data.message);
                } else {
                    setRackData((prevData) => {
                        const updatedSamples = prevData.samples.map((sample) =>
                            sample.id === sampleId ? { ...sample, value: newValue } : sample
                        );
                        return { ...prevData, samples: updatedSamples };
                    });
                    setInputValues((prev) => {
                        const newValues = { ...prev };
                        delete newValues[sampleId];
                        return newValues;
                    });
                }
            })
            .catch((error) => {
                console.error("Error updating sample:", error);
            });
    };


    const gridContent = () => {

        const isMC = selectedSite.value === "MC";

        if(rackData.details.type){
            return (
                <h2 style={{ minWidth: "350px", textAlign: "center", marginTop: "60px"}}>
                    {rackData.samples.length}/{rackData.details.total_samples}
                </h2>
            );
        } else {
            return (
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${rackData.details.width}, 1fr)`,
                        gap: "2px",
                        padding: "0 0 0 0",
                        ...(isMC ? { width: `${rackData.details.width * 50}px` } : {})
                    }}
                >
                    {/* Render the grid cells */}
                    {Array.from({ length: rackData.details.total_samples }).map((_, index) => {
                        const sample = rackData.samples.find(
                            (s) =>
                                s.lat === Math.ceil((index + 1) / 10) &&
                                s.lng === ((index + 1) % 10 === 0 ? 10 : (index + 1) % 10)
                        );

                        const className = sample ? (isMC ? 'mc-hba1c' : sample.class) : (isMC ? 'mc-noSample' : 'noSample');

                        return (
                            <div
                                key={index}
                                className={`${isMC ? 'mc-grid-sample' : 'grid-sample'} ${className}`}
                                onMouseOver={() => setHoveredSample(sample)}
                                onMouseOut={() => setHoveredSample(null)}

                            >
                                {isMC && sample ? sample.value : null}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    const handleSampleValueChange = (e, sampleId) => {
        const newValue = e.target.value;
        setEditedSamples((prev) => ({ ...prev, [sampleId]: newValue }));
        handleSave(sampleId, newValue);
    };


    const renderContent = () => {
        if (!selectedRack.name) {
            // Group racks by location
            const groupedRacks = {};
            racks.forEach((rack) => {
                if (!groupedRacks[rack.location]) {
                    groupedRacks[rack.location] = [];
                }
                groupedRacks[rack.location].push(rack);
            });


            return (
                <div id="select-rack">
                    <h2>Select a Rack</h2>
                    <select onChange={handleRackChange}>
                        <option value="">-- Select a Rack --</option>
                        {Object.entries(groupedRacks).map(([location, racks]) => (
                            <optgroup key={location} label={location}>
                                {racks.map((rack) => (
                                    <option key={rack.id} value={rack.key} data-id={rack.id}>
                                        {rack.name}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                </div>
            );
        } else if(!rackData){
            return (<div>Loading...</div>);
        } else {

            // A rack has been selected, so display the rack details

            return (
                <div>

                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "50%", paddingRight: "10px", position: "relative", minWidth: "250px" }}>
                            <div style={{ flex: "50%", paddingRight: "10px" }}>

                                <h2>Rack Details</h2>
                                {rackData && (
                                    <div>

                                        <div style={{ display: "flex", width: "100%", position: "sticky", top: 0 }}>

                                            {!readonly ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={sampleInput}
                                                        onChange={handleSampleInputChange}
                                                        onKeyPress={handleSampleInputKeyPress}
                                                        ref={inputRef}
                                                        style={{ flex: "1", marginRight: "5px" }}
                                                        placeholder="Sample ID..."
                                                    />
                                                <button onClick={handleAddSampleClick} style={{ flex: "none" }}>Add Sample</button>
                                                </>) : null }


                                        </div>

                                        <p>Name: {rackData.details.name}</p>
                                        <p>Location: {rackData.details.location}</p>
                                        <p>Size: {rackData.details.width}x{rackData.details.height}</p>
                                        <p style={{marginTop: "50px"}}><button onClick={handleChangeRackClick} style={{ flex: "none" }}>Change Rack</button></p>
                                    </div>
                                )}
                            </div>

                            {hoveredSample && (
                                <Tooltip>
                                    <p>{`Sample: ${hoveredSample.value}`}</p>
                                    <p>{`Position: ${hoveredSample.lat},${hoveredSample.lng}`}</p>
                                    <p>{`Date: ${hoveredSample.date}`}</p>
                                    {/* More sample details here */}
                                </Tooltip>
                            )}

                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                            {selectedSite.value !== 'MC' ? gridContent() : null}
                        </div>
                    </div>

                    {selectedSite.value === 'MC' ? (<div>{gridContent()}</div>) : null}

                    <div style={{ width: "100%" }}>
                        {/* Additional content that covers the entire width */}
                        <h2>Rack Samples</h2>
                        <table id="rack-samples-table">
                            <thead>
                            <tr>
                                <th>Move</th>
                                <th>Position</th>
                                <th>Sample</th>
                                <th>Rack</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rackData.samples.slice().reverse().map((sample) => (
                                <tr key={sample.id} className={hoveredSample && hoveredSample.id === sample.id ? 'highlight' : ''}>
                                <td><input type="checkbox" value={sample.id} checked={sample.moved} onChange={(e) => handleCheckboxChange(sample.id, e.target.checked, sample.value)} /></td>
                                    <td>{sample.lat},{sample.lng}</td>

                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                value={inputValues[sample.id] !== undefined ? inputValues[sample.id] : sample.value}
                                                onChange={(e) => handleInputChange(sample.id, e.target.value)}
                                                style={{ width: "100%" }} // Make sure the input takes up all available space
                                            />
                                            {isValueChanged(sample.id, sample.value) && (
                                                <i
                                                    title="Save Changes"
                                                    className="fa fa-check"
                                                    onClick={() => handleSave(sample.id, inputValues[sample.id])}
                                                ></i>
                                            )}
                                        </div>
                                    </td>

                                    <td>{rackData.details.name}</td>
                                    <td>{sample.date}</td>
                                </tr>
                            ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            );


        }

    };

    return renderContent();
}

export default StoreSample;