import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";

const api = process.env.NODE_ENV === 'production' ? 'https://ss-api.samplestack.me/' : 'http://localhost/';

function RackSetup({ selectedSite }){

    const [racks, setRacks] = useState([]);
    const isMountedRef = useRef(false);
    const [rackId, setRackId] = useState(null);
    const [newRackName, setNewRackName] = useState('');

    //States for input boxes
    const [modifyId , setModifyId] = useState('');
    const [rackName, setRackName] = useState('');
    const [location, setLocation] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [type, setType] = useState(false);


    //Fetch racks from API and populate dropdown
    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
            fetch(`${api}api/rack/select/${selectedSite.value}`)
                .then((response) => response.json())
                .then((data) => {
                    setRacks(data.racks);
                })
                .catch((error) => {
                    console.log("Error fetching data:", error);
                });
        }
    }, [selectedSite.value]);

    const handleSelectRack = () => {

        if(!rackId){
            toast.error("Please select a rack");
            return;
        }

        // Send a request to get rack information & sample counts
        //Send sampleId and siteId to API and handle response
        fetch(`${api}api/rack/modify/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                rack: rackId
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    console.error(data.message);
                    toast.error(data.message);
                } else {
                    setRackName(data.name);
                    setLocation(data.location);
                    setWidth(data.width);
                    setHeight(data.height);
                    setModifyId(data.id);
                    setType(data.type);
                }
            })
            .catch((error) => {
                console.error("Error selecting rack:", error);
                toast.error(error);
            });

    };

    const handleCreateRack = () => {

        if(!newRackName){
            toast.error("Please enter a rack name");
            return;
        }

        //Send api request to create new rack & return response
        fetch(`${api}api/rack/new/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                rackName: newRackName,
                site: selectedSite.value
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    console.error(data.message);
                    toast.error(data.message);
                } else {
                    //console.log(data);

                    //Update rack list
                    setRacks([...racks, data]);

                    //Reset input box
                    setNewRackName('');

                    //Display success message
                    toast.success("Successfully created new rack");

                    //Show new rack in modify section
                    setRackId(data.id);
                    setRackName(data.name);
                    setLocation(data.location);
                    setWidth(data.width);
                    setHeight(data.height);
                    setModifyId(data.id);
                    setType(data.type);

                    handleSelectRack();
                }
            })
            .catch((error) => {
                console.error("Error creating rack:", error);
                toast.error(error);
            });

    };

    const handleChanges = () => {

        if(!rackName || !location || !width || !height){
            toast.error("Please fill all fields");
            return;
        }

        //Confirm box
        if(!window.confirm(`Are you sure you want to modify this rack? The rack contents will be emptied before changes are made`)) return;

        //Send API request to modify rack
        fetch(`${api}api/rack/modify/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                rack: rackId,
                name: rackName,
                location: location,
                width: width,
                height: height,
                type: type
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    console.error(data.message);
                    toast.error(data.message);
                } else {
                    //setModifyId(null);
                    toast("Successfully modified rack");
                }
            })
            .catch((error) => {
                console.error("Error emptying rack:", error);
                toast.error(error);
            });

    };

    const renderContent = () => {

        // Group racks by location
        const groupedRacks = {};
        racks.forEach((rack) => {
            if (!groupedRacks[rack.location]) {
                groupedRacks[rack.location] = [];
            }
            groupedRacks[rack.location].push(rack);
        });

        return (
            <div>

                <div style={{display: "flex"}}>
                    <div style={{flex: "50%", paddingRight: "10px", position: "relative"}}>
                        <div style={{flex: "50%", paddingRight: "10px"}}>

                            <h2>Select rack to modify</h2>

                            <div style={{ display: "flex", width: "100%" }}>
                                <select
                                    style={{
                                        flex: "1",
                                        marginRight: "5px",
                                    }}
                                    onChange={(e) => {
                                        const selectElement = e.target;
                                        const selectedOption = selectElement.options[selectElement.selectedIndex];
                                        const dataId = selectedOption.getAttribute('data-id');
                                        setRackId(dataId);
                                    }}
                                >
                                    <option value="">-- Select a Rack --</option>
                                    {Object.entries(groupedRacks).map(([location, racks]) => (
                                        <optgroup key={location} label={location}>
                                            {racks.map((rack) => (
                                                <option key={rack.id} value={rack.key} data-id={rack.id}>
                                                    {rack.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}

                                </select>
                                <button
                                    style={{ flex: "none" }}
                                    onClick={handleSelectRack}
                                >
                                    Select Rack
                                </button>
                            </div>

                        </div>

                    </div>
                    <div style={{flex: "50%", paddingLeft: "10px"}}>

                        <h2>Create new rack</h2>

                        <div style={{ display: "flex", width: "100%" }}>
                            <input value={newRackName} onChange={e => setNewRackName(e.target.value)} placeholder="New rack name.." type="text" style={{ flex: "1", marginRight: "5px" }} />
                            <button onClick={handleCreateRack} style={{ flex: "none" }} >
                                Create Rack
                            </button>
                        </div>

                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "25px" }}>

                    {modifyId && (<table className="default-table" style={{width: "66%", margin: "auto"}}>
                        <thead>
                        <tr>
                            <th colSpan="2">Rack Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{width: "40%"}}>Rack Name</td>
                            <td><input type="text" value={rackName} onChange={(e) => setRackName(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: "40%"}}>Location</td>
                            <td><input type="text" value={location} onChange={(e) => setLocation(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: "40%"}}>Width</td>
                            <td><input type="number" value={width} onChange={(e) => setWidth(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: "40%"}}>Height</td>
                            <td><input type="number" value={height} onChange={(e) => setHeight(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: "40%"}}>Hide Grid (Eg. ED to LRI)</td>
                            <td><input type="checkbox" checked={type} onChange={(e) => setType(e.target.checked)}/></td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{textAlign: "center"}} colSpan="2">
                                <button onClick={handleChanges}>Save Changes</button>
                            </td>
                        </tr>
                        </tfoot>
                    </table>)}

                </div>
            </div>
        );

    };

    return renderContent();

}

export default RackSetup;