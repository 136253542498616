import React from 'react';
import logo from './static/small_logo.png';

function Sidebar({ selectedOption, selectedSite, handleOptionClick, handleClearSite }) {
    return (
        <div id="sidebar" style={{ position: 'relative' }}>
            <div id="sidebar-header">
                <img src={logo} alt="Logo" />
                <span>Sample Stack 2.0</span>
            </div>

            <div id="sidebar-subheader" className={selectedSite.name ? 'blue-bg' : 'green-bg'}>
                {selectedSite.name ? selectedSite.name : 'Select a site'}
            </div>
            <div
                className={`sidebar-option ${selectedOption === 'Store Sample' ? 'selected selected-color-1' : ''}`}
                onClick={() => handleOptionClick('Store Sample')}
            >
                <i className="fa fa-inbox option-icon" />
                <span className="option-text">Store Sample</span>
            </div>
            <div
                className={`sidebar-option ${selectedOption === 'Retrieve Sample' ? 'selected selected-color-3' : ''}`}
                onClick={() => handleOptionClick('Retrieve Sample')}
            >
                <i className="fa fa-upload option-icon" />
                <span className="option-text">Retrieve Sample</span>
            </div>
            <div
                className={`sidebar-option ${selectedOption === 'View Rack' ? 'selected selected-color-4' : ''}`}
                onClick={() => handleOptionClick('View Rack')}
            >
                <i className="fa fa-th-large option-icon" />
                <span className="option-text">View Rack</span>
            </div>
            <div
                className={`sidebar-option ${selectedOption === 'Empty Rack' ? 'selected selected-color-2' : ''}`}
                onClick={() => handleOptionClick('Empty Rack')}
            >
                <i className="fa fa-minus-square option-icon" />
                <span className="option-text">Empty Rack</span>
            </div>
            <div
                className={`sidebar-option ${selectedOption === 'Configure Racks' ? 'selected selected-color-5' : ''}`}
                onClick={() => handleOptionClick('Configure Racks')}
            >
                <i className="fa fa-line-chart option-icon" />
                <span className="option-text">Configure Racks</span>
            </div>
            <div
                id="change-site"
                style={selectedSite.name ? {} : {display: 'none'}}
                onClick={handleClearSite}
            >
                Change Site
            </div>
        </div>
    );
}

export default Sidebar;
