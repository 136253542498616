import React, {useState, useEffect, useRef} from 'react';
import Sidebar from './Sidebar';
import StoreSample from "./StoreSample";
import RetrieveSample from "./RetrieveSample";
import EmptyRack from "./EmptyRack";
import RackSetup from "./RackSetup";

const api = process.env.NODE_ENV === 'production' ? 'https://ss-api.samplestack.me/' : 'http://localhost/';

function Home() {
    const [selectedOption, setSelectedOption] = useState('Store Sample');
    const [selectedSite, setSelectedSite] = useState({value: '', name: ''});
    const [sites, setSites] = useState([]);
    const isMountedRef = useRef(false);

    const [isCompact, setIsCompact] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsCompact(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Fetch sites from API and populate dropdown
    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
            fetch(`${api}api/location/select`)
                .then(response => response.json())
                .then(data => {
                    setSites(data.sites);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, []);


    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleSiteChange = (e) => {
        const selectedSiteOption = e.target.options[e.target.selectedIndex];
        const selectedSiteValue = selectedSiteOption.value;
        const selectedSiteName = selectedSiteOption.text;

        setSelectedSite({ value: selectedSiteValue, name: selectedSiteName });
    };

    const handleClearSite = () => {
        setSelectedSite({value: '', name: ''});
    };

    const renderContent = () => {

        if (!selectedSite.name) {
            return (
                <div id="select-site">
                    <h2>Select a Site</h2>
                    <select onChange={handleSiteChange}>
                        <option value="">-- Select a Site --</option>
                        {sites.map(site => (
                            <option key={site.id} value={site.key}>{site.name}</option>
                        ))}
                    </select>
                </div>
            );
        }

        return (
            <div>
                {selectedOption === 'Store Sample' && <StoreSample selectedSite={selectedSite} />}
                {selectedOption === 'Retrieve Sample' && <RetrieveSample selectedSite={selectedSite} sites={sites} />}
                {selectedOption === 'View Rack' && <StoreSample selectedSite={selectedSite} readonly='true' />}
                {selectedOption === 'Empty Rack' && <EmptyRack selectedSite={selectedSite} />}
                {selectedOption === 'Configure Racks' && <RackSetup selectedSite={selectedSite} />}
            </div>
        );
    };

    return (
        <div id="container">
            <Sidebar
                isCompact={isCompact}
                selectedOption={selectedOption}
                selectedSite={selectedSite}
                handleOptionClick={handleOptionClick}
                handleClearSite={handleClearSite}
            />
            <div id="content">{renderContent()}</div>
        </div>
    );
}

export default Home;
