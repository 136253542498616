import React, { useState, useRef } from "react";
import {toast} from "react-toastify";
import Select from 'react-select';

const api = process.env.NODE_ENV === 'production' ? 'https://ss-api.samplestack.me/' : 'http://localhost/';

function RetrieveSample({ selectedSite, sites }) {

    const [sampleId, setSampleId] = useState('');
    const [siteId, setSiteId] = useState('all');
    const [highlightSampleId, setHighlightSampleId] = useState(false);
    const [searchResults, setSearchResults] = useState({ samples: [] });

    const [selectedOptions, setSelectedOptions] = useState([]);

    const groupedOptions = [
        {
            label: 'Chemistry',
            options: [
                { value: 'gelSample', label: 'Gel' },
                { value: 'fluorideSample', label: 'Fluoride' },
                { value: 'urineSample', label: 'Urine' },
            ],
        },
        {
            label: 'Haematology',
            options: [
                { value: 'edtaSample', label: 'EDTA' },
                { value: 'citrateSample', label: 'Citrate' },
            ],
        },
        {
            label: 'Other',
            options: [
                { value: 'whiteSample', label: 'Plain/White' },
            ],
        },
    ];

    const optionColors = {
        gelSample: '#660000',
        fluorideSample: '#CFCB15',
        urineSample: '#15A6CF',
        edtaSample: '#cf1414',
        citrateSample: '#7BCF15',
        whiteSample: '#ffffff'
    };

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                paddingLeft: 20,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? '#E6E6E6'
                            : null,
                ':before': {
                    content: '""',
                    display: 'inline-block',
                    width: 10,
                    height: 10,
                    marginRight: 10,
                    backgroundColor: optionColors[data.value],
                    borderRadius: 5,
                    border: '1px solid #E6E6E6'
                }
            };
        }
    };


    const inputRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchSample();
        }
    }

    const handleSearchSample = () => {
        if(!sampleId){
            setHighlightSampleId(true);
            toast.error("Please enter a search value");
            return;
        }

        setHighlightSampleId(false);

        const selectedFilters = selectedOptions.map(option => option.value);

        //Send sampleId and siteId to API and handle response
        fetch(`${api}api/sample/search/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                searchValue: sampleId,
                searchSite: siteId,
                filters: selectedFilters
            })
        })
            .then((response) => response.json())
            .then((data) => {
                // Update the rackData based on the response
                if(data.error) {
                    // The rack is full or some other error occurred
                    console.error(data.message);
                } else {
                    setSearchResults(data);
                    //console.log(data);
                }
                inputRef.current.focus();
                inputRef.current.select();
            })
            .catch((error) => {
                console.error("Error searching sample:", error);
            });

    }

    return (
        <div>
            <h2>Retrieve Sample</h2>
            <div style={{ display: "flex", width: "100%" }}>
                <input
                    ref={inputRef}
                    type="text"
                    onChange={(e) => {
                        setSampleId(e.target.value);
                        // Reset highlighting on change
                        setHighlightSampleId(false);
                    }}
                    onKeyDown={handleKeyDown}
                    style={{
                        flex: "1",
                        marginRight: "5px",
                        borderColor: highlightSampleId ? 'red' : null,
                        backgroundColor: highlightSampleId ? 'pink' : null
                    }}
                    placeholder="Sample ID..."
                />
                <select
                    style={{ flex: "1", marginRight: "5px" }}
                    onChange={(e) => setSiteId(e.target.value)}
                >
                    <option value="all">All</option>
                    {sites.map(site => (
                        <option key={site.id} value={site.key}>{site.name}</option>
                    ))}
                </select>

                <Select
                    isMulti
                    options={groupedOptions}
                    onChange={setSelectedOptions}
                    placeholder="Additional Filters..."
                    styles={colourStyles}
                    style={{ flex: "1", marginRight: "5px" }}
                />


                <button onClick={handleSearchSample} style={{ marginLeft: "5px", flex: "none" }}>Search</button>
            </div>

            <div style={{ width: "100%" }}>
                <h2>Search Results</h2>
                <table id="rack-samples-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Moved</th>
                        <th>Sample</th>
                        <th>Rack</th>
                        <th>Position</th>
                        <th>Site</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {searchResults.samples && searchResults.samples.length > 0 ? (
                        searchResults.samples.map(result => (
                            <>
                                <tr className={!result.in_rack ? 'history' : ''} key={result.id}>
                                    <td>
                                        <div style={{position: 'relative'}}>
                                            {!result.in_rack ?
                                                <i style={{cursor: "pointer"}} title="Historical Sample" className="fa fa-history option-icon"></i> :
                                                <i className="fa fa-flask option-icon"></i>
                                            }
                                        </div>
                                    </td>

                                    <td>{result.moved ? "Yes" : "No"}</td>
                                    <td style={{cursor: "default"}} className={result.class}>{result.value}</td>
                                    <td>{result.rack}</td>
                                    <td>{result.lat},{result.lng}</td>
                                    <td>{result.site}</td>
                                    <td>{result.date}</td>
                                </tr>
                                {result.history && result.history.length > 0 && result.history.map(hist => (
                                    <tr className='sample-history' key={hist.history_id}>
                                        <td><i style={{cursor: "pointer"}} title="Historical Record" className="fa fa-arrow-circle-o-right option-icon"></i></td>
                                        <td>{/* You can add history-specific data here, e.g. hist.action */}</td>
                                        <td>{hist.sample_number}</td>
                                        <td colSpan='3'>{hist.action}</td>
                                        <td>{hist.time}</td>
                                    </tr>
                                ))}
                            </>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">No Samples Found</td>
                        </tr>
                    )}
                    </tbody>


                </table>
            </div>


        </div>
    );
}

export default RetrieveSample;
